import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from 'styled-components'

import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"

const SkewedDiv = styled.div`
  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #bb0000;
    transform-origin: top left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
  }
`

const PageTierOneFinancialEvent = () => {
  const data = useStaticQuery(graphql`
    query PageTierOneFinancialEventQuery {
      prismicTierOneFinancialEvent {
        _previewable
        data {
          page_title
          page_subtitle
          page_short_description
          page_hero_background {
            alt
            gatsbyImageData(
              width: 1920
            )
          }
          red_bubble_text
          second_section_text
          button_name
          button_link {
            link_type
            uid
            url
          }
          column_icon_one {
            alt
            gatsbyImageData(
              width: 80
            )
          }
          column_icon_three {
            alt
            gatsbyImageData(
              width: 80
            )
          }
          column_icon_two {
            alt
            gatsbyImageData(
              width: 80
            )
          }
          column_title_one
          column_title_three
          column_title_two
          cta_text_one
          cta_text_two
          event_date
          event_hosts
          event_location
          event_location_address
          event_time
          first_section_text
          first_section_subtext
          invite_button_link {
            link_type
            uid
            url
          }
          invite_button_name
        }
      }
    }
  `)

  const doc = data.prismicTierOneFinancialEvent
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)
  const colIconOne = getImage(doc.data.column_icon_one)
  const colIconTwo = getImage(doc.data.column_icon_two)
  const colIconThree = getImage(doc.data.column_icon_three)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc}
      />

      <div className="relative border-t border-gray-700" style={{ display: "grid", background: "#1a1a1a", minHeight: "500px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 500,
          }}
          alt={doc.data.page_title}
          image={imgHero}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
            <div className="flex flex-col justify-center">
              <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4 uppercase">
                {doc.data.page_title ? doc.data.page_title : "Page Title"}
              </h2>
              {doc.data.page_subtitle
                ?
                <h3 className="text-white text-xl md:text-2xl font-bold text-center mt-6 uppercase">
                  {doc.data.page_subtitle}
                </h3>
                :
                null
              }

              <div className="w-full flex items-center justify-center mt-8">
                {doc.data.button_link.link_type === "Document" || doc.data.button_link.link_type === "Any"
                  ?
                    <Link to={doc.data.button_link.uid ? `/${doc.data.button_link.uid}` : "/"}>
                      <button className="w-full md:w-72 bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-16 py-3">
                        {doc.data.button_name ? doc.data.button_name : "Button Name"}
                      </button>
                    </Link>
                  :
                  doc.data.button_link.link_type === "Web" || doc.data.button_link.link_type === "Media"
                  ?
                    <a
                      href={doc.data.button_link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full md:w-72 bg-white opacity-70 hover:opacity-90 text-base md:text-lg text-site-red uppercase tracking-wider px-16 py-3">
                        {doc.data.button_name ? doc.data.button_name : "Button Name"}
                      </button>
                    </a>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        {doc.data.red_bubble_text
          ?
          <div className="absolute top-0 w-full flex flex-col items-center -mt-16">
            <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 rounded-3xl shadow-lg">
              <h2 className="text-white font-medium text-center text-lg md:text-xl">{doc.data.red_bubble_text}</h2>
            </div>
          </div>
          :
          null
        }

        <div className="pt-72 pb-24 md:pt-40 md:pb-32">
          <div className="w-11/12 md:w-10/12 max-w-screen-md mx-auto">
            <h2 className="text-4xl md:text-6xl text-center font-extrabold uppercase">{doc.data.first_section_text}</h2>
            <p className="text-2xl md:text-3xl text-center mt-3">{doc.data.first_section_subtext}</p>
          </div>

          <div className="w-11/12 md:w-10/12 max-w-screen-md mx-auto mt-16">
            <div>
              <h2 className="text-5xl font-extrabold text-site-red text-center mb-2">{doc.data.event_date}</h2>
              <hr />
              <p className="text-lg uppercase text-center tracking-widest font-semibold mt-1">DATE</p>
            </div>

            <div className="mt-8">
              <h2 className="text-5xl font-extrabold text-site-red text-center mb-2">{doc.data.event_time}</h2>
              <hr />
              <p className="text-lg uppercase text-center tracking-widest font-semibold mt-1">TIME</p>
            </div>

            <div className="mt-8">
              <h2 className="text-5xl font-extrabold text-site-red text-center mb-2">{doc.data.event_location}</h2>
              <h3 className="text-4xl font-extrabold text-site-red text-center mb-2">{doc.data.event_location_address}</h3>
              <hr />
              <p className="text-lg uppercase text-center tracking-widest font-semibold mt-1">LOCATION</p>
            </div>

            <div className="mt-8">
              <h2 className="text-5xl font-extrabold text-site-red text-center mb-2">{doc.data.event_hosts}</h2>
              <hr />
              <p className="text-lg uppercase text-center tracking-widest font-semibold mt-1">HOSTED BY</p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-300 pt-32 pb-24">
        <SkewedDiv className="absolute top-0 right-0 bg-site-red w-full md:w-11/12 2xl:w-10/12 h-24 -mt-12"></SkewedDiv>
        <div className="absolute top-0 left-0 right-0 h-24 flex items-center -mt-12 px-4">
          <h2 className="text-lg xl:text-2xl text-white text-center md:max-w-lg xl:max-w-2xl mx-auto">{doc.data.second_section_text}</h2>
        </div>

        <div className="w-11/12 lg:w-10/12 max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12">
          <div>
            <div className="h-32 w-32 bg-white flex items-center justify-center rounded-full mx-auto mb-6">
              <GatsbyImage
                image={colIconOne}
                alt="icon"
              />
            </div>
            <h3 className="text-site-red md:text-2xl xl:text-3xl font-extrabold text-center uppercase">{doc.data.column_title_one}</h3>
          </div>

          <div>
            <div className="h-32 w-32 bg-white flex items-center justify-center rounded-full mx-auto mb-6">
              <GatsbyImage
                image={colIconTwo}
                alt="icon"
              />
            </div>
            <h3 className="text-site-red md:text-2xl xl:text-3xl font-extrabold text-center uppercase">{doc.data.column_title_two}</h3>
          </div>

          <div>
            <div className="h-32 w-32 bg-white flex items-center justify-center rounded-full mx-auto mb-6">
              <GatsbyImage
                image={colIconThree}
                alt="icon"
              />
            </div>
            <h3 className="text-site-red md:text-2xl xl:text-3xl font-extrabold text-center uppercase">{doc.data.column_title_three}</h3>
          </div>
        </div>

        <div className="w-full flex flex-col items-center justify-center mt-16">
          <h3 className="text-4xl font-extrabold mb-3">{doc.data.cta_text_one}</h3>
          <h3 className="text-4xl font-extrabold mb-8 uppercase">{doc.data.cta_text_two}</h3>

          {doc.data.invite_button_link.link_type === "Document" || doc.data.invite_button_link.link_type === "Any"
            ?
              <Link to={doc.data.invite_button_link.uid ? `/${doc.data.invite_button_link.uid}` : "/"}>
                <button className="w-full bg-white text-lg md:text-xl text-gray-800 font-bold shadow-md uppercase tracking-wider px-8 md:px-24 py-3">
                  {doc.data.invite_button_name ? doc.data.invite_button_name : "Button Name"}
                </button>
              </Link>
            :
            doc.data.invite_button_link.link_type === "Web" || doc.data.invite_button_link.link_type === "Media"
            ?
              <a
                href={doc.data.invite_button_link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-full md:w-72 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                  {doc.data.invite_button_name ? doc.data.invite_button_name : "Button Name"}
                </button>
              </a>
            :
            null
          }
        </div>
      </div>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageTierOneFinancialEvent)
